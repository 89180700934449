body {
  font-family: Barlow;
  letter-spacing: -0.02em;
}

.modal-background {
  background-color: rgba(171, 171, 171, 0.4) !important;
}

div[id^='font-picker'] {
  visibility: hidden;
}

#card-number,
#card-expiry,
#card-cvc {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #d3dce6;
  color: #4a5568;
  display: block;
  margin-top: 5px;
  outline: none;
  padding: 11px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: #f4f4f4;
}

@media screen and (max-width: 730px) {
  body {
    padding: 0;
    background: #fff;
  }
}
